


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";

import {
  VehicleParkingUsageTrackingAssociationDetails,
  LicensePlateRecognitionHistory,
  ParkingHistory,
} from "@/api/models";
import api from "@/api/api";
import { ParkingLotBasic } from "@/api/models";
import {
  formatDurationSeconds,
  formatDurationShowSeconds,
} from "@/libs/dateUtils";
import TimestampEditField from "@/components/TimestampEditField.vue";
export default Vue.extend({
  name: "VehicleParkingUsageRecordDetails",

  props: {
    showNextPrevControls: {
      type: Boolean,
      required: false,
      default: false,
    },
    recordId: {
      type: Number,
      required: true,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    TimestampEditField,
  },

  data() {
    return {
      showLprEntryImage: false,
      showDetectionEntryImage: false,
      showVehicleEntryImage: false,
      showLprExitImage: false,
      showVehicleExitImage: false,
      showDetectionExitImage: false,

      isLoading: false,
      isEditing: false,
      images: [] as Array<string>,
      imagesShowIndexes: {
        entry_detection: -1,
        entry_vehicle: -1,
        entry_lpr: -1,
        exit_detection: -1,
        exit_vehicle: -1,
        exit_lpr: -1,
        parking: [] as Array<number>,
      },
      recordDetails:
        null as VehicleParkingUsageTrackingAssociationDetails | null,
      parkingLotInfo: null as ParkingLotBasic | null,
    };
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin"]),
    parkingHistoryCategoryType(): string {
      if (
        this.recordDetails &&
        this.recordDetails?.parking_history_records &&
        this.recordDetails?.parking_history_records[0].parking_zone_id
      ) {
        return "Parking Zones";
      } else if (
        this.recordDetails &&
        this.recordDetails?.parking_history_records &&
        this.recordDetails?.parking_history_records[0].special_area_id
      ) {
        return "Special Areas";
      }
      return "Parking Spots";
    },
    parkingHistorySpots(): string {
      let idsNames = [] as Array<string>;
      if (this.recordDetails?.parking_history_records) {
        for (const h of this.recordDetails?.parking_history_records) {
          if (h.name && !idsNames.includes(h.name)) {
            idsNames.push(h.name);
          } else if (
            h.parking_zone_id &&
            !idsNames.includes(h.parking_zone_id.toString())
          ) {
            idsNames.push(h.parking_zone_id.toString());
          } else if (
            h.special_area_id &&
            !idsNames.includes(h.special_area_id.toString())
          ) {
            idsNames.push(h.special_area_id.toString());
          }
        }
      }
      return idsNames.join(", ");
    },

    showAnprFields(): boolean {
      if (this.isSuperAdmin && this.parkingLotInfo?.is_anpr_feature_enabled) {
        return true;
      }

      // For other user types
      if (
        this.parkingLotInfo?.is_anpr_feature_enabled &&
        this.parkingLotInfo?.is_anpr_feature_visible_to_customers
      ) {
        return true;
      }

      return false;
    },
  },

  filters: {
    formatDurationSeconds,
    formatDurationShowSeconds,
  },

  async mounted() {
    await this.initData();
  },
  methods: {
    preloadImage() {
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_entry_record.lpr_image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_entry_record.lpr_image_path_url;
        image.onload = () => {
          this.showLprEntryImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_entry_record.crop_image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_entry_record.crop_image_path_url;
        image.onload = () => {
          this.showVehicleEntryImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_entry_record &&
        this.recordDetails.lpr_entry_record.image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_entry_record.image_path_url;
        image.onload = () => {
          this.showDetectionEntryImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_exit_record.lpr_image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_exit_record.lpr_image_path_url;
        image.onload = () => {
          this.showLprExitImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_exit_record.crop_image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_exit_record.crop_image_path_url;
        image.onload = () => {
          this.showVehicleExitImage = true;
        };
      }
      if (
        this.recordDetails &&
        this.recordDetails.lpr_exit_record &&
        this.recordDetails.lpr_exit_record.image_path_url
      ) {
        const image = new Image();
        image.src = this.recordDetails.lpr_exit_record.image_path_url;
        image.onload = () => {
          this.showDetectionExitImage = true;
        };
      }
    },

    async initData() {
      this.isEditing = false;
      try {
        this.isLoading = true;
        let recordDetails = await api.getVehicleParkingUsageRecordDetails(
          this.recordId
        );
        if (recordDetails) {
          this.recordDetails = recordDetails;
          // Also, load Parking Lot info to compute showAnprFields
          this.fetchParkingLotData(recordDetails.parking_lot_id);

          this.addRecordImagesToViewer();
          this.preloadImage();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchParkingLotData(lotId: number) {
      let parkingLotInfo = await api.getParkingLotBasicInfo(lotId);
      if (parkingLotInfo) {
        this.parkingLotInfo = parkingLotInfo;
      }
    },

    /**
     * Add all entry/exit and parking images to viewer images list.
     */
    addRecordImagesToViewer() {
      let imageIndexCount = 0;
      this.images = [];
      if (this.recordDetails?.lpr_entry_record?.lpr_image_path_url) {
        this.imagesShowIndexes.entry_lpr = -1;
        this.images.push(
          this.recordDetails?.lpr_entry_record?.lpr_image_path_url
        );
        this.imagesShowIndexes.entry_lpr = imageIndexCount;
        imageIndexCount++;
      }
      if (this.recordDetails?.lpr_entry_record?.image_path_url) {
        this.imagesShowIndexes.entry_detection = -1;
        this.images.push(this.recordDetails?.lpr_entry_record?.image_path_url);
        this.imagesShowIndexes.entry_detection = imageIndexCount;
        imageIndexCount++;
      }
      if (this.recordDetails?.lpr_entry_record?.crop_image_path_url) {
        this.imagesShowIndexes.entry_vehicle = -1;
        this.images.push(
          this.recordDetails?.lpr_entry_record?.crop_image_path_url
        );
        this.imagesShowIndexes.entry_vehicle = imageIndexCount;
        imageIndexCount++;
      }
      this.imagesShowIndexes.parking = [];
      if (this.recordDetails?.merged_parking_timeline_records) {
        for (const record of this.recordDetails
          ?.merged_parking_timeline_records) {
          if (record.event_type == "spot_tracking_event") {
            let historyRecord = record.event_data as ParkingHistory;
            if (historyRecord.clean_image_url != null) {
              this.images.push(historyRecord.clean_image_url);
              this.imagesShowIndexes.parking.push(imageIndexCount);
              imageIndexCount++;
            }
          } else if (record.event_type == "zone_lpr_event") {
            let historyRecord =
              record.event_data as LicensePlateRecognitionHistory;
            if (historyRecord.lpr_image_path_url) {
              this.imagesShowIndexes.exit_lpr = -1;
              this.images.push(historyRecord.lpr_image_path_url);
              this.imagesShowIndexes.parking.push(imageIndexCount);
              imageIndexCount++;
            }
            if (historyRecord.image_path_url) {
              this.imagesShowIndexes.exit_detection = -1;
              this.images.push(historyRecord.image_path_url);
              this.imagesShowIndexes.parking.push(imageIndexCount);
              imageIndexCount++;
            }
            if (historyRecord.crop_image_path_url) {
              this.imagesShowIndexes.exit_vehicle = -1;
              this.images.push(historyRecord.crop_image_path_url);
              this.imagesShowIndexes.parking.push(imageIndexCount);
              imageIndexCount++;
            }
          }
        }
      }

      if (this.recordDetails?.lpr_exit_record?.lpr_image_path_url) {
        this.imagesShowIndexes.exit_lpr = -1;
        this.images.push(
          this.recordDetails?.lpr_exit_record?.lpr_image_path_url
        );
        this.imagesShowIndexes.exit_lpr = imageIndexCount;
        imageIndexCount++;
      }
      if (this.recordDetails?.lpr_exit_record?.image_path_url) {
        this.imagesShowIndexes.exit_detection = -1;
        this.images.push(this.recordDetails?.lpr_exit_record?.image_path_url);
        this.imagesShowIndexes.exit_detection = imageIndexCount;
        imageIndexCount++;
      }
      if (this.recordDetails?.lpr_exit_record?.crop_image_path_url) {
        this.imagesShowIndexes.exit_vehicle = -1;
        this.images.push(
          this.recordDetails?.lpr_exit_record?.crop_image_path_url
        );
        this.imagesShowIndexes.exit_vehicle = imageIndexCount;
        imageIndexCount++;
      }
    },

    removeHistoryRecord(index: number) {
      if (!this.recordDetails) {
        return;
      }
      this.recordDetails.parking_history_records = [
        ...this.recordDetails.parking_history_records.slice(0, index),
        ...this.recordDetails.parking_history_records.slice(index + 1),
      ];
    },

    show(index: number) {
      this.$viewerApi({
        options: {
          initialViewIndex: index,
          focus: false,
          button: false,
        },
        images: this.images,
      });
    },

    getParkingEventImageIndex(imageUrl: string) {
      let startIndex = this.imagesShowIndexes.parking[0] || 0;
      let endIndex =
        this.imagesShowIndexes.parking[
          this.imagesShowIndexes.parking.length - 1
        ];
      let parkingImages = this.images.slice(startIndex, endIndex + 1);
      let imageIndex = parkingImages.indexOf(imageUrl) + startIndex;
      console.log({
        startIndex,
        endIndex,
        parkingImages,
        imageUrl,
        imageIndex,
      });
      return imageIndex;
    },

    closePopup() {
      this.showLprEntryImage = false;
      this.showDetectionEntryImage = false;
      this.showVehicleEntryImage = false;
      this.showLprExitImage = false;
      this.showDetectionExitImage = false;
      this.showVehicleExitImage = false;
      this.$emit("close-form");
    },

    openInNewTab(url: string) {
      window.open(url, "_blank");
    },

    async saveRecord() {
      if (this.recordDetails && this.recordDetails.lpr_entry_record) {
        this.recordDetails.lpr_entry_record.vehicle_type =
          this.recordDetails.lpr_entry_record.vehicle_type &&
          this.recordDetails.lpr_entry_record.vehicle_type.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_type
            : null;
        this.recordDetails.lpr_entry_record.vehicle_brand =
          this.recordDetails.lpr_entry_record.vehicle_brand &&
          this.recordDetails.lpr_entry_record.vehicle_brand.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_brand
            : null;
        this.recordDetails.lpr_entry_record.vehicle_color =
          this.recordDetails.lpr_entry_record.vehicle_color &&
          this.recordDetails.lpr_entry_record.vehicle_color.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_color
            : null;
        this.recordDetails.lpr_entry_record.vehicle_country =
          this.recordDetails.lpr_entry_record.vehicle_country &&
          this.recordDetails.lpr_entry_record.vehicle_country.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_country
            : null;
        this.recordDetails.lpr_entry_record.vehicle_region =
          this.recordDetails.lpr_entry_record.vehicle_region &&
          this.recordDetails.lpr_entry_record.vehicle_region.trim() != ""
            ? this.recordDetails?.lpr_entry_record.vehicle_region
            : null;
      }
      if (this.recordDetails && this.recordDetails.lpr_exit_record) {
        this.recordDetails.lpr_exit_record.vehicle_type =
          this.recordDetails.lpr_exit_record.vehicle_type &&
          this.recordDetails.lpr_exit_record.vehicle_type.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_type
            : null;
        this.recordDetails.lpr_exit_record.vehicle_brand =
          this.recordDetails.lpr_exit_record.vehicle_brand &&
          this.recordDetails.lpr_exit_record.vehicle_brand.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_brand
            : null;
        this.recordDetails.lpr_exit_record.vehicle_color =
          this.recordDetails.lpr_exit_record.vehicle_color &&
          this.recordDetails.lpr_exit_record.vehicle_color.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_color
            : null;
        this.recordDetails.lpr_exit_record.vehicle_country =
          this.recordDetails.lpr_exit_record.vehicle_country &&
          this.recordDetails.lpr_exit_record.vehicle_country.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_country
            : null;
        this.recordDetails.lpr_exit_record.vehicle_region =
          this.recordDetails.lpr_exit_record.vehicle_region &&
          this.recordDetails.lpr_exit_record.vehicle_region.trim() != ""
            ? this.recordDetails?.lpr_exit_record.vehicle_region
            : null;
      }

      let recordUpdated = await api.putVehicleParkingUsageRecordDetails(
        this.recordId,
        this.recordDetails
      );
      if (recordUpdated) {
        this.$dialog.message.info("Saved changes successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
        this.closePopup();
      } else {
        this.$dialog.message.error(
          "Unable to save changes. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async approveRecord() {
      let recordUpdated = await api.approveVehicleParkingUsageRecordDetails(
        this.recordId
      );
      if (recordUpdated) {
        this.$dialog.message.info("Approved and Verified successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
        this.closePopup();
      } else {
        this.$dialog.message.error(
          "Unable to approve record. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async rejectRecord() {
      let recordUpdated = await api.rejectVehicleParkingUsageRecordDetails(
        this.recordId
      );
      if (recordUpdated) {
        this.$dialog.message.info("Rejected ANPR matching.", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
        this.closePopup();
      } else {
        this.$dialog.message.error(
          "Unable to reject record. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
  },

  watch: {
    needsInit(showingData) {
      if (showingData) {
        this.initData();
      } else {
        this.showLprEntryImage = false;
        this.showVehicleEntryImage = false;
        this.showDetectionEntryImage = false;
        this.showLprExitImage = false;
        this.showVehicleExitImage = false;
        this.showDetectionExitImage = false;
      }
    },
    recordId() {
      this.initData();
    },
  },
});
